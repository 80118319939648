import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../store/redux/breadCrumbsData';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useMemo, useState } from 'react';
import { authContext } from '../../store/context/authContext';
import SalesData from '../../modules/dashboard/components/SalesData';
import DurationTabs from '../../modules/dashboard/components/DurationTabs';
import { getApisData, handleDurationClick } from '../../modules/dashboard/utils/HelperFunctions';
import DistributorsData from '../../modules/dashboard/components/DistributorsData';
import Loader from '../../modules/dashboard/components/Loader';
import CustomersData from '../../modules/dashboard/components/CustomersData';
import VehiclesData from '../../modules/dashboard/components/VehiclesData';
import { generalGet } from '../../API/api';
import { dashboardDataSet, dashboardDurations } from '../../config/FixedData';
import ProvidersData from '../../modules/dashboard/components/ProvidersData';
import { ICarData } from '../../modules/products/types/interfaces';
import { TOptions } from '../../types/types';
import ModalContainer from '../../components/ModalContainer';
import CustomizeDateRange from '../../modules/dashboard/components/CustomizeDateRange';
import ListHeader from '../../components/ListHeader';
import Select from 'react-select';
import { sortStyles } from '../../utils/SelectStyles';
import { hasPermission } from '../../utils/HelperFunctions';
import WelcomePage from './WelcomePage';
const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const { catchError } = useContext(authContext);
  const [selectedBrand, setSelectedBrand] = useState<string>();
  const [selectedProvider, setSelectedProvider] = useState<any>();
  const [salesData, setSalesData] = useState<any>();
  const [brandData, setBrandData] = useState<any>();
  const [vehiclesData, setVehiclesData] = useState<any>();
  const [customerData, setCustomerData] = useState<any>();
  const [providersData, setProvidersData] = useState<any>();
  const [distData, setDistData] = useState<any>();
  const [activeTab, setActiveTab] = useState<string>('1 Year');
  const [selectedDuration, setSelectedDuration] = useState<string>('');
  const [salesDataSet, setSalesDataSet] = useState([dashboardDataSet]);
  const [brandsDataSet, setBrandsDataSet] = useState([dashboardDataSet]);
  const [singleProviderData, setSingleProviderData] = useState<any>();
  const [singleProviderDataSet, setSingleProviderDataSet] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const dateDuration = useMemo(() => {
    const diff = Math.ceil((dateRange?.endDate.getTime() - dateRange?.startDate.getTime()) / (1000 * 3600 * 24) / 30);
    const durationBy = dateRange ? (diff <= 1 ? 'days' : diff <= 13 ? 'month' : 'years') : null;
    return durationBy;
  }, [dateRange]);

  const [brands, setBrands] = useState<TOptions[]>([]);

  const { data: brandsQData, isSuccess: brandsQIsSuccess } = useQuery({
    queryKey: ['Brands'],
    queryFn: () => generalGet(`/admin/makes?with_models=1&with_variants=1`),
    refetchOnWindowFocus: false,
    enabled: hasPermission(['dashboard.show'])
  });

  useEffect(() => {
    if (brandsQIsSuccess) {
      const reqData = brandsQData?.data?.data;
      setBrands(
        reqData?.map((car: ICarData) => {
          return { label: car.name, value: car.id };
        })
      );
      setSelectedBrand(reqData[0]?.id);
    }
  }, [brandsQIsSuccess, brandsQData]);

  useEffect(() => {
    dispatch(
      setBreadCrumbsData({
        links: [{ label: hasPermission(['dashboard.show']) ? t('dashboard') : t('home'), path: '/' }],
        page_title: hasPermission(['dashboard.show']) ? t('dashboard') : t('home')
      })
    );
  }, [t, setBreadCrumbsData]);

  const { data, isSuccess, isLoading, error } = useQuery(getApisData('Sales data', i18n.language, selectedDuration, activeTab, 'sales-analysis', selectedBrand!));

  const {
    data: distributorData,
    isSuccess: distributorIsSuccess,
    isLoading: distributorIsLoading,
    error: distributorError
  } = useQuery(getApisData('Distributor data', i18n, selectedDuration, activeTab, 'distributors-analysis', selectedBrand!));

  const {
    data: providersQData,
    isSuccess: providersIsSuccess,
    isLoading: providersIsLoading,
    error: providersError
  } = useQuery(getApisData('Providers data', i18n, selectedDuration, activeTab, 'providers-analysis', selectedBrand!));

  const {
    data: vehiclesQData,
    isSuccess: vehiclesIsSuccess,
    isLoading: vehiclesIsLoading,
    error: vehiclesError
  } = useQuery(getApisData('Vehicles data', i18n, selectedDuration, activeTab, 'vehicles-analysis', selectedBrand!));

  const {
    data: customerQData,
    isSuccess: customerIsSuccess,
    isLoading: customerIsLoading,
    error: customerError
  } = useQuery(getApisData('Customer data', i18n, selectedDuration, activeTab, 'customers-analysis', selectedBrand!));

  const {
    data: brandQData,
    isSuccess: brandIsSuccess,
    error: brandError
  } = useQuery(getApisData('Brands data', i18n, selectedDuration, activeTab, `brand-analysis/${selectedBrand}`, selectedBrand!));

  const {
    data: singleProviderQData,
    isSuccess: singleProviderIsSuccess,
    error: singleProviderError
  } = useQuery({
    queryKey: ['Single provider', i18n.language, selectedDuration, activeTab, selectedProvider],
    queryFn: () => generalGet(`/dashboard/single-provider-analysis/${selectedProvider?.id}?${selectedDuration}&vehicle_make_id=${selectedBrand}`),
    refetchOnWindowFocus: false,
    enabled: !!selectedBrand && !!selectedProvider?.id && !!selectedDuration && hasPermission(['dashboard.show'])
  });

  useEffect(() => {
    handleDurationClick(activeTab, setSelectedDuration, dateDuration == 'years' ? salesData?.sales_by_year : salesData?.sales_by_month, setSalesDataSet, 'count', '', dateRange);
  }, [activeTab, salesData, dateRange, dateDuration]);

  useEffect(() => {
    handleDurationClick(
      activeTab,
      setSelectedDuration,
      dateDuration == 'years' ? brandData?.applications_by_year : brandData?.applications_by_month,
      setBrandsDataSet,
      'count',
      '',
      dateRange
    );
  }, [activeTab, brandData, dateRange, dateDuration]);

  useEffect(() => {
    handleDurationClick(
      activeTab,
      setSelectedDuration,
      dateDuration == 'years' ? singleProviderData?.applicationsByYear : singleProviderData?.applicationsByMonth,
      setSingleProviderDataSet,
      'application_accepted_value',
      'paid_amount',
      dateRange
    );
  }, [activeTab, singleProviderData, dateRange, dateDuration]);

  useEffect(() => {
    if (isSuccess) {
      setSalesData(data.data.data);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (vehiclesIsSuccess) {
      setVehiclesData(vehiclesQData.data.data);
    }
  }, [vehiclesIsSuccess, vehiclesQData]);

  useEffect(() => {
    if (brandIsSuccess) {
      setBrandData(brandQData.data.data);
    }
  }, [brandIsSuccess, brandQData]);

  useEffect(() => {
    if (singleProviderIsSuccess) {
      setSingleProviderData(singleProviderQData.data.data[0][0]);
    }
  }, [singleProviderIsSuccess, singleProviderQData]);

  useEffect(() => {
    if (distributorIsSuccess) {
      setDistData(distributorData.data.data[0]);
    }
  }, [distributorIsSuccess, distributorData]);

  useEffect(() => {
    if (customerIsSuccess) {
      setCustomerData(customerQData.data.data);
    }
  }, [customerIsSuccess, customerQData]);

  useEffect(() => {
    if (providersIsSuccess) {
      setProvidersData(providersQData.data.data[0]);
      setSelectedProvider({ ...providersQData.data.data[0][0], title: providersQData?.data.data[0][0]?.name });
    }
  }, [providersIsSuccess, providersQData]);

  useEffect(() => {
    if (error || distributorError || customerError || brandError || vehiclesError || providersError || singleProviderError) {
      catchError(error || distributorError || customerError || brandError || vehiclesError || providersError || singleProviderError);
    }
  }, [error, distributorError, customerError, catchError, brandError, vehiclesError, providersError, singleProviderError]);

  if ((hasPermission(['dashboard.show']) && !selectedBrand) || isLoading || distributorIsLoading || customerIsLoading || vehiclesIsLoading || providersIsLoading) return <Loader />;

  if (!hasPermission(['dashboard.show'])) {
    return <WelcomePage />;
  } else {
    return (
      <div className="page_content service_details_page">
        <ListHeader customClass="row noMargin">
          <h2>{t('dashboardCharts.select_brand_duration')}</h2>
        </ListHeader>
        <div className="dashboard-header">
          <div>
            <Select
              defaultValue={brands.filter((item: any) => item?.value == selectedBrand)}
              placeholder={t('dashboardCharts.select_brand')}
              isSearchable={false}
              isClearable={false}
              isMulti={false}
              options={brands}
              className={`select-drop-down `}
              noOptionsMessage={() => t('no_options')}
              classNamePrefix="react-select"
              styles={sortStyles as any}
              onChange={(e) => {
                setSelectedBrand(e?.value as string);
              }}
            />
          </div>
          <DurationTabs t={t} duration setOpenModal={setOpenModal} tabs={dashboardDurations} activeTab={activeTab} setActiveTab={setActiveTab} setDateRange={setDateRange} />
        </div>
        <SalesData salesData={salesData} dataSet={salesDataSet} />
        <VehiclesData vehiclesData={vehiclesData} dataSet={brandsDataSet} />
        {providersData?.length > 0 && (
          <ProvidersData
            selectedProvider={selectedProvider}
            providersData={providersData}
            setSelectedProvider={setSelectedProvider}
            singleProviderDataSet={singleProviderDataSet}
          />
        )}
        <CustomersData customerData={customerData} />
        {distData?.length > 0 && <DistributorsData distData={distData} />}

        {openModal && (
          <ModalContainer small customClass="x-small">
            <CustomizeDateRange setDateRange={setDateRange} dateRange={dateRange} setActiveTab={setActiveTab} setOpenModal={setOpenModal} />
          </ModalContainer>
        )}
      </div>
    );
  }
};

export default Dashboard;
