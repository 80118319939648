import { useContext, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import Button from '../../../components/buttons/Button';
import { generalCreate, generalGet } from '../../../API/api';
import { authContext } from '../../../store/context/authContext';
import { useTranslation } from 'react-i18next';
import DateRangeField from '../../../components/formInputs/DateRangeField';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import SectionHeader from '../../../components/SectionHeader';
import { ICarData, ICarModel, IOffer } from '../types/interfaces';
import moment from 'moment';
import FormSkeleton from '../../../components/loaders/FormSkeleton';
import { scrollToError } from '../../../utils/HelperFunctions';
import { isAfter, isToday, isValid, parse, set } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { setOfferIsActive } from '../../../store/redux/breadCrumbsData';
import Checkbox from '../../../components/formInputs/Checkbox';
import { customStyles } from '../../../utils/SelectStyles';
import { TOptions } from '../../../types/types';
import OfferSelectedModels from './OfferSelectedModels';

const CreateOfferForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, offerId } = useParams();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState<IOffer>();
  const [profitRates, setProfitRates] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const { catchError } = useContext(authContext);
  const isOfferActive = useSelector((store: any) => store.breadCrumbsData.breadCrumbsData.offerIsActive);
  const [carData, setCarData] = useState<ICarData[]>([]);
  const [carMakeOptions, setCarMakeOptions] = useState<TOptions[]>([]);
  const [carModelOptions, setCarModelOptions] = useState<TOptions[]>([]);
  const [allModelsOptions, setAllModelsOptions] = useState<TOptions[]>([]);
  const [allModelsData, setAllModelsData] = useState<ICarModel[]>([]);
  const [fetchedModels, setFetchedModels] = useState<string[]>([]);

  const { t } = useTranslation();
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['product', id],
    queryFn: () => generalGet(`/products/${id}`),
    enabled: !!id
  });

  const {
    data: offerData,
    isSuccess: offerIsSuccess,
    isLoading: offerIsLoading
  } = useQuery({
    queryKey: ['offer', offerId, refetch],
    queryFn: () => generalGet(`/offers/${offerId}`),
    enabled: !!offerId
  });

  useEffect(() => {
    isSuccess && setProfitRates(data.data.data.profit_rate);
  }, [isSuccess, data]);

  useEffect(() => {
    offerIsSuccess && setOffer(offerData.data.data);
    if (offerData?.data?.data?.is_active) {
      dispatch(setOfferIsActive(offerData?.data?.data?.is_active));
    } else {
      dispatch(setOfferIsActive(false));
    }
    setFetchedModels(offerData?.data?.data?.vehicle_models.map((object: any) => object.id));
  }, [offerIsSuccess, offerData]);

  //Vehical Make and Models

  const { data: carDataRes, isSuccess: carDataResIsSuccess } = useQuery({
    queryKey: ['carData'],
    queryFn: () => generalGet(`/admin/makes?with_models=1&with_variants=1`),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const reqData = carDataRes?.data?.data;
    setCarData(reqData);
    setAllModelsData(reqData?.flatMap((car: ICarData) => car?.models));
    isSuccess &&
      setCarMakeOptions(
        reqData?.map((car: ICarData) => {
          return { label: car.name, value: car.id };
        })
      );
  }, [carDataResIsSuccess, carDataRes]);

  useEffect(() => {
    const data: any = [];
    allModelsData?.map((car: any) => {
      data.push({ label: car.name, value: car.id });
    });
    setAllModelsOptions(data);
  }, [allModelsData]);

  // useEffect(() => {
  //   if (productData) {
  //     const selectedCarMake = carData?.find((car: ICarData) => car.id === productData?.balloon_conditions[index]?.vehicle_model[0]?.vehicle_make_id);
  //     const data: any = [];
  //     selectedCarMake?.models?.map((car: any) => {
  //       data.push({ label: car.name, value: car.id });
  //     });
  //     setCarModelOptions(data);
  //     // const models = productData?.balloon_conditions[index]?.vehicle_model.map((item: any) => item.id) || [];
  //     // formik.setFieldValue(`balloon_conditions.${index}.vehicle_model_id`, [...models])
  //   }
  // }, [productData, index, carData]);

  const validationSchema = Yup.object({
    start_date: Yup.string().required(t('required')),
    end_date: Yup.string()
      .required(t('required'))
      .test('greaterThanOrEqualCurrentDate', t('date_must'), function (value) {
        const currentDate = new Date();
        if (!value) {
          return true;
        }
        const parsedEnteredDate = parse(value, 'yyyy-MM-dd', new Date());
        if (!isValid(parsedEnteredDate)) {
          return false;
        }
        return isAfter(parsedEnteredDate, currentDate) || isToday(parsedEnteredDate);
      }),
    admin_fee: Yup.number()
      .typeError(t('must_no'))
      .min(0, t('must_great'))
      .max(100, t('must_min'))
      .test('profit-required', t('required'), function (value) {
        const { profit_rate } = this.parent;
        if (!profit_rate[0]) {
          return !!value;
        }
        return true;
      }),
    profit_rate: Yup.array().of(Yup.number().min(0, t('must_great')).max(100, t('must_min')).nullable())
  });

  if (offerIsLoading || isLoading) return <FormSkeleton featuredSections={1} offer />;

  return (
    <div className="create_user_form modal_content" ref={formRef}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          start_date: offer?.start_date ? moment(offer?.start_date).format('YYYY-MM-DD') : '',
          end_date: offer?.end_date ? moment(offer?.end_date).format('YYYY-MM-DD') : '',
          admin_fee: offer?.admin_fee || '',
          profit_rate: offer?.profit_rate
            ? profitRates.length == offer?.profit_rate.length
              ? offer?.profit_rate
              : [...offer.profit_rate, ...new Array(profitRates.length).fill(null)].slice(0, profitRates.length)
            : new Array(profitRates.length).fill(null),
          is_active: offer?.is_active || false,
          vehicle_model_ids: fetchedModels || []
        }}
        onSubmit={(values) => {
          setLoading(true);
          values.is_active = isOfferActive;
          if (offerId) {
            generalCreate({
              route: `/admin/offers/${offerId}`,
              values: { ...values, product_id: id, _method: 'PUT' }
            })
              .then((res) => {
                setLoading(false);
                toast.success(`${t('offer')} ${t('updated')} ${t('successfully')}`);
                setRefetch(true);
                navigate(`/products/${id}`);
              })
              .catch((error) => {
                catchError(error, setLoading);
              });
            return;
          }
          generalCreate({
            route: '/admin/offers',
            values: { ...values, product_id: id }
          })
            .then((res) => {
              setLoading(false);
              toast.success(`${t('offer')} ${t('created')} ${t('successfully')}`);
              setRefetch(true);
              navigate(`/products/${id}`);
            })
            .catch((error) => {
              catchError(error, setLoading);
            });
        }}
      >
        {(formik) => (
          <>
            <Form>
              <div className="form_inputs">
                <SectionHeader title={t('offerDetails')}>
                  <div className="inputs_group">
                    <FieldWrapper title={t('offerDuration')}>
                      <DateRangeField
                        formik={formik}
                        selectionRange={selectionRange}
                        setSelectionRange={setSelectionRange}
                        endValue={formik.values.end_date}
                        startValue={formik.values.start_date}
                      />
                    </FieldWrapper>
                    <FieldWrapper noPadding />
                  </div>
                  <div className="inputs_group">
                    <div className="inputs_group">
                      <FieldWrapper
                        customPadding
                        title={t('carMake')}
                        inputPlaceholder={t('selectCarMake')}
                        options={carMakeOptions}
                        onChange={(e) => {
                          const selectedCarMake = carData?.find((car: ICarData) => car.id === e?.value);
                          const data: any = [];
                          selectedCarMake?.models?.map((car: any) => {
                            data.push({ label: car.name, value: car.id });
                          });
                          setCarModelOptions(data);
                          // formik.setFieldValue(`vehicle_model_id.${}`, [])
                        }}
                        selectStyle={customStyles}
                        select
                        // defaultValue={productData && carMakeOptions?.find((option) => option.value == productData?.balloon_conditions[index]?.vehicle_model[0]?.vehicle_make_id)}
                        // key={productData && carMakeOptions?.find((option) => option.value == productData?.balloon_conditions[index]?.vehicle_model[0]?.vehicle_make_id)}
                      />
                      {carModelOptions?.length == 0 && <FieldWrapper noPadding />}
                      {/* <FieldWrapper
          customPadding
          title={t('carModel')}
          inputName={`balloon_conditions.${index}.vehicle_model_id`}
          inputError={formik?.errors?.balloon_conditions && formik?.errors?.balloon_conditions[index]?.vehicle_model_id}
          inputTouched={formik?.touched?.balloon_conditions && formik?.touched?.balloon_conditions[index]?.vehicle_model_id}
          inputPlaceholder={t('selectCarModel')}
          selectRef={modelRef}
          // ref={inputRef}
          options={carModelOptions}
          onChange={(e: any) => formik.setFieldValue(`balloon_conditions.${index}.vehicle_model_id`, e?.value)}
          selectStyle={customStyles}
          select
          defaultValue={productData && carModelOptions?.find((option) => option.value == productData?.balloon_conditions[index]?.vehicle_model?.id)}
          key={productData && carModelOptions?.find((option) => option.value == productData?.balloon_conditions[index]?.vehicle_model?.id)}
        /> */}
                    </div>
                    {carModelOptions?.length > 0 && (
                      <div className="inputs_group">
                        <FieldWrapper title={t('selectCarModel')}>
                          <div className="checkboxes_wrapper offer">
                            {/* <label>
                <input
                  checked={all}
                  id={`all`}
                  type="checkbox"
                  value={'all'}
                  onChange={(e) => {
                    setAll(!all);
                    formik?.setFieldValue(`balloon_conditions.${index}.vehicle_model_id`, !all ? carModelOptions.map((item: any) => item.value) : []);
                  }}
                />
                {t('all')}
              </label> */}
                            {carModelOptions?.map((item: any, i: number) => (
                              <Checkbox
                                name={`vehicle_model_ids`}
                                key={i}
                                item={item}
                                value={formik?.values?.vehicle_model_ids?.some((checkedItem: string) => checkedItem == item?.value)}
                                checked={formik?.values?.vehicle_model_ids}
                                formik={formik}
                              />
                            ))}
                          </div>
                          <p className="error check">
                            <ErrorMessage name={`vehicle_model_id`} />
                          </p>
                        </FieldWrapper>
                      </div>
                    )}
                  </div>
                  <OfferSelectedModels formik={formik} allModelsOptions={allModelsOptions} />
                  <div className="inputs_group">
                    <FieldWrapper
                      title={t('adminFee')}
                      inputName={`admin_fee`}
                      inputPlaceholder={'15%'}
                      inputError={formik.errors.admin_fee as string}
                      inputTouched={formik.touched.admin_fee as boolean}
                      input
                      border
                      type="number"
                      showUnit
                      unit="%"
                    />
                    <FieldWrapper noPadding />
                    <FieldWrapper noPadding />
                  </div>
                </SectionHeader>
                <SectionHeader title={t('OfferProfitRates')} customStyle="last">
                  <div className="inputs_group">
                    {profitRates.map((item: number, index: number) => (
                      <FieldWrapper
                        key={index}
                        title={index + 1 + ' ' + t('year')}
                        inputName={`profit_rate[${index}]`}
                        inputPlaceholder={'25%'}
                        inputError={formik.errors.profit_rate && (formik.errors.profit_rate[index]! as string)}
                        inputTouched={Array?.isArray(formik?.touched?.profit_rate) && !!formik?.touched?.profit_rate[index]}
                        input
                        border
                        type="number"
                        showUnit
                        unit="%"
                      />
                    ))}
                    {profitRates?.length < 3 && (
                      <>
                        <FieldWrapper noPadding />
                        <FieldWrapper noPadding />
                      </>
                    )}
                  </div>
                </SectionHeader>
              </div>
              <div className="form_button reverse">
                <Button
                  type={loading ? 'button' : 'submit'}
                  loading={loading}
                  onClick={() => {
                    scrollToError(!formik.isValid, formRef);
                  }}
                >
                  <span className="bold">{t('save')}</span>
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateOfferForm;
