import { useEffect } from "react";
import AdminPanelHeader from "../../components/layout/AdminPanelHeader";
import VerifyAdminForm from "../../modules/auth/components/VerifyAdminForm";
import Cookies from "js-cookie";
import { useQuery } from "@tanstack/react-query";
import { generalGet } from "../../API/api";
import { is } from "date-fns/locale";
import { useNavigate } from "react-router-dom";

const VerifyAdmin = () => {
    const navigate = useNavigate();
    const { data, isSuccess } = useQuery({
        queryKey: ['user'],
        queryFn: () => generalGet(`/user`),
        refetchOnWindowFocus: false,
      });
    useEffect(() => {
       if(isSuccess) {
        navigate("/")
       }
      }, [data, isSuccess]);
    return (
        <div className="login_page_container authlayout verification">
        <div className="login_container verification">
            <AdminPanelHeader />
            <VerifyAdminForm />
        </div>
        </div>
    );
}

export default VerifyAdmin;