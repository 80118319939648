import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import { useEffect, useState, useTransition } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generalGet } from '../../../API/api';
import TableSkeleton from '../../../components/loaders/TableSkeleton';
import ListHeader from '../../../components/ListHeader';
import SearchInputField from '../../../components/SearchInputField';
import { hasPermission, searchFilterLogic } from '../../../utils/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { IProductData } from '../../../modules/products/types/interfaces';
import ProductsTableContainer from '../../../modules/products/components/ProductsTableContainer';
import { Pagination, Stack } from '@mui/material';
import { IMeta } from '../../../modules/applications/types/interfaces';
import Select, { GroupBase, OptionsOrGroups } from 'react-select';
import { paginationControlStyles, sortStyles } from '../../../utils/SelectStyles';

const Products = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  dispatch(
    setBreadCrumbsData({
      links: [{ label: t('products'), path: '/products' }],
      page_title: t('products')
    })
  );
  const [isPending, startTransition] = useTransition();
  const [selectedOption, setSelectedOption] = useState(null);

  const [searchInput, setSearchInput] = useState('');
  const [refetch, setRefetch] = useState(false);
  const [products, setProducts] = useState<IProductData[]>([]);
  const [shownList, setShownList] = useState<IProductData[]>([]);
  const [metaData, setMetaData] = useState<IMeta>();
  const [currentRoute, setCurrentRoute] = useState('/products?page=1&limit=25');
  const [perPageOptions, setPerPageOptions] = useState([
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' }
  ]);
  const [selectedPerPage, setSelectedPerPage] = useState({ value: '25', label: '25' });

  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ['products', refetch, currentRoute, selectedPerPage],
    queryFn: () => generalGet(currentRoute),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const reqData = data?.data.data;
    isSuccess &&
      setProducts(
        reqData?.map((item: IProductData) => {
          const productName = item.service_provider.name[i18n.language as keyof typeof item.service_provider.name];
          return { ...item, productName };
        })
      );
    setMetaData(data?.data.meta);
  }, [isSuccess, data]);

  const tableHeaders = [
    // { label: t("id"), customClass: "pro_id_col" },
    // { label: t("logo"), customClass: "pro_logo_col" },
    { label: t('serviceProvider'), customClass: 'registration_date_col text-center' },
    { label: t('financialDuration'), customClass: 'text-center' },
    { label: t('profitRate'), customClass: 'text-center profit' },
    { label: t('adminFees'), customClass: 'text-center' },
    // { label: t("offers"), customClass: "status_col" },
    hasPermission(['products.edit']) && { label: t('actions'), customClass: 'actions_col' }
  ];

  useEffect(() => {
    setSelectedOption(null);
    setShownList(products);
    // if (searchInput) {
    //   const results = searchFilterLogic({ searchInput: searchInput, listOfData: products, keys: ['id', 'productName'] });
    //   startTransition(() => {
    //     setShownList(results);
    //   });
    // }
  }, [products, searchInput]);

  const [page, setPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCurrentRoute(`/products?page=${value}&limit=${selectedPerPage.value}`);
  };

  useEffect(() => {
    setCurrentRoute(`/products?page=1&limit=${selectedPerPage.value}`);
  }, [selectedPerPage]);

  if (isLoading) return <TableSkeleton columns={6} withoutButton />;

  return (
    <div className="services-page-container">
      {/* <ListHeader>
        <div className="filter_container">
          <SearchInputField placeholder={t('search_by_id_Name')} setSearchInput={setSearchInput} />
        </div>
      </ListHeader> */}
      <ProductsTableContainer tableHeaders={tableHeaders} data={shownList} noDataMessage={t('No_products_found')} setRefetchData={setRefetch} lessColumns />
      {metaData && (
        <Stack spacing={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '1rem' }}>
          <div className="" style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            {t('rowsPerPage')}{' '}
            <Select
              value={selectedPerPage}
              placeholder={t('perPage')}
              isSearchable={false}
              isClearable={false}
              isMulti={false}
              options={perPageOptions}
              className={`select-drop-down `}
              noOptionsMessage={() => t('no_options')}
              classNamePrefix="react-select"
              styles={{ ...sortStyles, ...paginationControlStyles }}
              onChange={(e) => {
                e && setSelectedPerPage(e);
                setCurrentRoute(`/products?page=${metaData?.current_page}&limit=${e?.value}`);
              }}
            />
          </div>
          {Number(metaData?.last_page) > 1 && <Pagination count={Number(metaData?.last_page)} page={page} onChange={handleChange} />}{' '}
        </Stack>
      )}
    </div>
  );
};

export default Products;
