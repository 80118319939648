// import "moment/locale/ar";
import moment from 'moment/moment';
import { IComment } from '../types/interfaces';
import { useEffect, useState } from 'react';
import ApplicationReplyAdd from './ApplicationReplyAdd';
import { arrowTopIcon, deleteIcon, editCommentIcon, editIcon, plusIcon, sendIcon } from '../../../config/variables';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import Button from '../../../components/buttons/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from '../../../config/axiosConfig';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ReplayComment from './ReplayComment';

export default function ApplicationCommentsItem({
  comment,
  setActiveDelete,
  setFieldId
}: {
  comment: IComment;
  setActiveDelete: (value: boolean) => void;
  setFieldId: (value: number) => void;
}) {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [updateField, setUpdateField] = useState(false);

  const updateCommentMutation = useMutation({
    mutationFn: async (values: FormValues) =>
      await axiosInstance(`comments/${comment?.id}`, {
        data: values,
        method: 'PUT'
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['comments'] });
      toast.success(`${t('updateComment')} ${t('successfully')}`);
      setUpdateField(false);
    }
  });

  interface FormValues {
    body: string;
  }
  const initialValues: FormValues = { body: comment?.body || '' };
  const validationSchema = Yup.object({
    body: Yup.string().min(3).max(150).required(t('required'))
  });
  const onSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    updateField && updateCommentMutation.mutate(values);
  };
  const [repliesOpen, setRepliesOpen] = useState(false);
  const [shownRepliesCount, setShowRepliesCount] = useState(3);

  function getTime(date: string) {
    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const currDate = moment(date).format('YYYY-MM-DD');

    if (currDate === today) {
      return `Today, ${moment(date).format('hh:mm A')}`;
    } else if (currDate === yesterday) {
      return `Yesterday, ${moment(date).format('hh:mm A')}`;
    } else {
      return moment(date).format('DD MMM YYYY, hh:mm A');
    }
  }
  return (
    <div className="comments-item" key={`comment-${comment.id}`}>
      <div className="comment-controls">
        <span onClick={() => setUpdateField(true)}>{editCommentIcon}</span>
        {!updateField && (
          <span
            onClick={() => {
              setActiveDelete(true);
              setFieldId(comment?.id);
            }}
          >
            {deleteIcon}
          </span>
        )}
        {updateField && (
          <svg
            onClick={() => {
              updateField && setUpdateField(false);
            }}
            className="delete-comment"
            width="15"
            height="15"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3047 12.5938C13.0703 12.8281 12.6406 12.8281 12.4062 12.5938L7.25 7.39844L2.05469 12.5938C1.82031 12.8281 1.39062 12.8281 1.15625 12.5938C0.921875 12.3594 0.921875 11.9297 1.15625 11.6953L6.35156 6.5L1.15625 1.34375C0.921875 1.10938 0.921875 0.679688 1.15625 0.445312C1.39062 0.210938 1.82031 0.210938 2.05469 0.445312L7.25 5.64062L12.4062 0.445312C12.6406 0.210938 13.0703 0.210938 13.3047 0.445312C13.5391 0.679688 13.5391 1.10938 13.3047 1.34375L8.10938 6.5L13.3047 11.6953C13.5391 11.9297 13.5391 12.3594 13.3047 12.5938Z"
              fill="black"
            ></path>
          </svg>
        )}
      </div>
      <div className="comments-text">
        <div className="comments-text-top">
          <h4 className="comment-author">{comment?.user?.first_name}</h4>
          <div className="comments-replies-date">{getTime(comment.created_at)}</div>
        </div>
        {updateField ? (
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {(formik) => (
              <>
                <Form>
                  <div className="add-comment">
                    <FieldWrapper
                      inputName={'body'}
                      inputError={formik.errors.body as string}
                      inputTouched={formik.touched.body as boolean}
                      textArea
                      customPadding
                      maxLength={150}
                    />
                    {/* {updateCommentMutation.isPending?true:false} */}
                    <Button
                      text={sendIcon}
                      customClass="icon-only"
                      loading={updateCommentMutation.isPending ? true : false}
                      commentBtn
                      onClick={function () {
                        formik.submitForm();
                        setTimeout(() => {
                          formik.resetForm();
                        }, 500);
                      }}
                      type="submit"
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
        ) : (
          <p className="comment-body">{comment.body}</p>
        )}
      </div>
      {
        <Accordion
          expanded={repliesOpen}
          onChange={(e) => {
            e.stopPropagation();
            setRepliesOpen((prev) => !prev);
          }}
        >
          <AccordionSummary
            sx={{
              '&.Mui-focusVisible': {
                backgroundColor: '#000'
              }
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={`header-container ${repliesOpen && 'expand'}`}
          >
            <p className="comments-footer">
              {t('replies')} <div className={`comments-replies-arrow ${repliesOpen ? 'comments-replies-arrow-open' : ''}`}>{arrowTopIcon}</div>
            </p>
          </AccordionSummary>
          {
            <AccordionDetails>
              <div className="comments-replies">
                <ApplicationReplyAdd commentable_id={comment.commentable_id} comment_id={comment.id} />
                {comment.replies?.length > 0 &&
                  comment.replies.slice(0, shownRepliesCount).map((reply) => <ReplayComment replay={reply} setActiveDelete={setActiveDelete} setFieldId={setFieldId} />)}
                {shownRepliesCount < comment.replies.length && (
                  <Button
                    customClass="load-more_btn"
                    onClick={() => setShowRepliesCount((prev) => prev + 3)}
                    text={
                      <div className="load-more_btn_content">
                        {plusIcon} {t('loadMore')}
                      </div>
                    }
                  />
                )}
              </div>
            </AccordionDetails>
          }
        </Accordion>
      }
    </div>
  );
}
