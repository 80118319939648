import { useContext, useEffect, useState } from 'react';
import { authContext } from '../../../store/context/authContext';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setBreadCrumbsData } from '../../../store/redux/breadCrumbsData';
import FormSteps from '../../../components/FormSteps';
import { generalGet } from '../../../API/api';
import { useQuery } from '@tanstack/react-query';
import SingleStep from '../../../modules/applications/components/SingleStep';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/buttons/Button';
import ModalContainer from '../../../components/ModalContainer';
import { approvedStepIcon, pendingStepIcon, rejectedStepIcon } from '../../../config/variables';
import InitialLoader from '../../../components/loaders/InitialLoader';
import ApplicationStatusModal from '../../../modules/applications/components/ApplicationStatusModal';
import ProductFormSteps from '../../../modules/applications/components/ProductFormSteps';
import { set } from 'date-fns';
import { ICurrentStep } from '../../../modules/applications/types/interfaces';
import Loader from '../../../modules/dashboard/components/Loader';

const ProductSteps = () => {
  const { catchError } = useContext(authContext);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState<ICurrentStep>();
  const [activeStep, setActiveStep] = useState<string>('');
  const [singleStepData, setSingleStepData] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean | number>(true);
  const [openStatusModal, setOpenStatusModal] = useState<boolean>(false);
  const [refetch, setRefetch] = useState(false);
  const [steps, setSteps] = useState<{ name: { en: string; ar: string }; id: string; order: number }[]>([]);
  const [nextStep, setNextStep] = useState<string>('');
  const [unauthorized, setUnauthorized] = useState(false);
  const dispatch = useDispatch();

  dispatch(
    setBreadCrumbsData({
      page_title: t('steps')
    })
  );

  const { data, isSuccess, isLoading, error } = useQuery({
    queryKey: ['product steps', i18n, refetch, id],
    queryFn: () => generalGet('/application-products/' + id + '/steps'),
    refetchOnWindowFocus: false
  });
  const {
    data: stepData,
    isSuccess: isSuccessStepData,
    isLoading: isLoadingStepData,
    error: errorStepData
  } = useQuery({
    queryKey: ['stepData', i18n, refetch, activeStep, id],
    queryFn: () => generalGet('/application-products/' + id + '/steps' + '/' + activeStep),
    refetchOnWindowFocus: false,
    enabled: !!activeStep
  });

  useEffect(() => {
    error && catchError(error);
    errorStepData && catchError(errorStepData);
  }, [error, errorStepData]);

  useEffect(() => {
    const reqData = data?.data?.data;
    const sortedSteps = reqData?.sort((a: any, b: any) => a.order - b.order);

    setSteps(sortedSteps);

    const currentStepInfo = sortedSteps?.filter((step: { is_current_step?: boolean }) => {
      return step?.is_current_step;
    });

    currentStepInfo && setCurrentStep(currentStepInfo[0]);
  }, [isSuccess, data]);

  useEffect(() => {
    const reqData = stepData?.data?.data;

    setSingleStepData(reqData);
  }, [isSuccessStepData, stepData]);
  useEffect(() => {
    currentStep && setActiveStep(currentStep?.id);
    
  }, [currentStep]);

  console.log(currentStep?.id, activeStep, 'currentStep');
  

  useEffect(() => {
    const findNextStep = steps?.slice(steps?.findIndex((step) => step.id === activeStep) + 1);
    setNextStep(findNextStep[0]?.id);
  }, [activeStep]);

  if (isLoadingStepData || !activeStep) return <Loader />;
  return (
    <div className="page_content">
      <div className="form-steps-content">
        <ProductFormSteps steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} currentStep={currentStep} />
        <div className={`form `}>
          {/* {activeStep == 0 && <SearchForDeal setActiveStep={setActiveStep} />} */}
          {singleStepData && currentStep && (
            <SingleStep
              // key={index}
              data={singleStepData}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isCurrentStep={currentStep?.id === singleStepData?.id}
              openModal={setOpenStatusModal}
              nextStepId={nextStep}
              appId={currentStep?.application_request_id}
              setUnauthorized={setUnauthorized}
            />
          )}
        </div>
      </div>
      {openModal && currentStep?.id === activeStep && (
        <ModalContainer small>
          <div className="common-modal status">
            <div className="statusIcon">
              {currentStep?.status?.value === 1 && pendingStepIcon}
              {currentStep?.status?.value === 2 && approvedStepIcon}
              {currentStep?.status?.value === 3 && rejectedStepIcon}
            </div>
            <p>{currentStep?.message?.[i18n.language as 'en' | 'ar']} </p>
            <div className="buttons">
              <Button
                onClick={() => {
                  setOpenModal(false);
                  window.scroll(0, 0);
                  // navigate(`/applications`)
                }}
              >
                <span className="bold">{t('close')}</span>
              </Button>
            </div>
          </div>
        </ModalContainer>
      )}
      {openStatusModal && singleStepData && (
        <ModalContainer small overflow>
          <ApplicationStatusModal
            setRefetchData={setRefetch}
            setModal={setOpenStatusModal}
            successMsg={t('statusChanged')}
            warningMsg={t('changeAppStatus')}
            pId={id}
            currentStatus={currentStep?.status?.value}
            application_ID={currentStep?.application_request_id}
            // isLastStep={productStepsData?.current_step?.id === productStepsData?.steps[productStepsData?.steps.length - 1]?.id}
          />
        </ModalContainer>
      )}
      {/* {unauthorized && (
        <ModalContainer small>
          <div className="common-modal status">
            <h4>{`${t('unauthorizedMsg')}`}</h4>

            <div className="buttons">
              <Button
                onClick={() => {
                  setUnauthorized(false);
                  window.scroll(0, 0);
                  // navigate(`/applications`)
                }}
              >
                <span className="bold">{t('close')}</span>
              </Button>
            </div>
          </div>
        </ModalContainer>
      )} */}
    </div>
  );
};

export default ProductSteps;
