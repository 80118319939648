import { axisClasses } from '@mui/x-charts';
import dayjs from 'dayjs';
import moment from 'moment';
import { Dispatch } from 'react';
import { generalGet } from '../../../API/api';
import { hasPermission } from '../../../utils/HelperFunctions';

export const getApisData = (key: string, i18n: any, selectedDuration: string, activeTab: string, route: string, vehicle_make_id: string) => {
  return {
    queryKey: [key, i18n.language, selectedDuration, activeTab, vehicle_make_id],
    queryFn: () => generalGet(`/dashboard/${route}?${selectedDuration}&vehicle_make_id=${vehicle_make_id}`),
    refetchOnWindowFocus: false,
    enabled: !!vehicle_make_id && !!selectedDuration && hasPermission(['show.dashboard'])
  };
};
export const handleDurationClick = (
  activeTab: string,
  setSelectedDuration: Dispatch<React.SetStateAction<string>>,
  data: any,
  setDataSet: Dispatch<React.SetStateAction<{ x: string; value: string }[]>>,
  key: string = 'count',
  otherKey?: string,
  date?: {
    startDate: any;
    endDate: any;
  }
) => {
  const { startDate, today, formattedStart, formattedToday, durationBy } = getDates(activeTab, date);
  setSelectedDuration(`start_date=${formattedStart}&end_date=${formattedToday}`);
  createDataSet(startDate, today, data, setDataSet, durationBy, key, otherKey);
};
const getDates = (activeTab: string, date?: any) => {
  let today: any;
  let startDate: any;
  let durationBy: 'year' | 'month' | 'days' | 'years';
  if (activeTab !== 'customize') {
    let number = Number(activeTab.split(' ')[0]);
    durationBy = activeTab.split(' ')[1].toLowerCase() as 'year' | 'month' | 'days';
    today = dayjs();
    startDate = today.subtract(number, durationBy);
  } else {
    today = dayjs(new Date(date?.endDate));
    startDate = dayjs(new Date(date?.startDate));
    const diff = Math.ceil((date?.endDate.getTime() - date?.startDate.getTime()) / (1000 * 3600 * 24) / 30);
    durationBy = diff <= 1 ? 'days' : diff <= 12 ? 'month' : 'years';
  }
  let formattedStart = startDate.format('YYYY-MM-DD');
  let formattedToday = today.format('YYYY-MM-DD');
  return {
    startDate,
    today,
    formattedStart,
    formattedToday,
    durationBy
  };
};

const createDataSet = (
  startDate: dayjs.Dayjs,
  today: dayjs.Dayjs,
  data: any,
  setDataSet: Dispatch<React.SetStateAction<{ x: string; value: string }[]>>,
  durationBy: 'year' | 'month' | 'days' | 'years',
  key: string = 'count',
  otherKey?: string
) => {
  const { daysDuration, durations } = durationsModify(startDate, today, durationBy);
  if (durationBy == 'days') {
    setDataSet(createDaysDataSet(daysDuration, durations, data, key, otherKey));
  } else {
    setDataSet(createDurationDataSet(durations, data, key, otherKey, durationBy == 'years'));
  }
};

const createDaysDataSet = (daysDuration: string[], durations: string[], data: any, key?: string, otherKey?: string) => {
  const DataMap = new Map<string, { value: any; other_value: any }>();
  daysDuration.map((day: string) => {
    Array.from(new Set(durations)).map((item: string) => {
      DataMap.set(moment(day).format('MM-DD'), {
        value: data?.hasOwnProperty(item) && data[item]['days']?.hasOwnProperty(day) ? (otherKey ? data[item]['days'][day][key!] : data[item]['days'][day]) : 0,
        other_value: data?.hasOwnProperty(item) && data[item]['days']?.hasOwnProperty(day) ? (otherKey ? data[item]['days'][day][otherKey!] : null) : 0
      });
    });
  });

  return Array.from(DataMap, ([x, { value, other_value }]) => ({ x, value, other_value }));
};
const createDurationDataSet = (durations: string[], data: any, key: string, otherKey?: string, isYears?: boolean) => {
  let collectiveData: { x: string; value: string; other_value?: string }[] = [];
  durations.map((item: string) => {
    collectiveData.push({
      x: moment(item).format(isYears ? 'YYYY' : 'MMM,YYYY'),
      value: data?.hasOwnProperty(item) ? data[item][`${key}`] : 0,
      other_value: otherKey ? (data?.hasOwnProperty(item) ? data[item][`${otherKey}`] : 0) : null
    });
  });
  return collectiveData;
};

const durationsModify = (startDate: dayjs.Dayjs, today: dayjs.Dayjs, durationBy: 'year' | 'month' | 'days' | 'years') => {
  let durations: string[] = [];
  let daysDuration: string[] = [];
  durationBy = durationBy == 'year' ? 'month' : durationBy;
  while (startDate.isBefore(today) || startDate.isSame(today, durationBy)) {
    durations.push(startDate.format(durationBy == 'years' ? 'YYYY' : 'YYYY-MM'));
    durationBy == 'days' && daysDuration.push(startDate.format('YYYY-MM-DD'));
    startDate = startDate.add(1, durationBy);
  }

  return { durationBy, durations, daysDuration };
};

export const getChartOptions = (label: string, yLabel: string = 'Value', color: string = '#000') => {
  return {
    yAxis: [
      {
        label: label
      }
    ],
    series: [
      {
        dataKey: 'value',
        label: yLabel,
        valueFormatter: (value: any) => valueFormatter(value),
        color
      }
    ],
    height: 350,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: 'translateX(-10px)'
      }
    }
  };
};
export function valueFormatter(value: number | null) {
  return `${value}`;
}
