import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandAccordion from '../../products/components/productForm/ExpandAccordion';
import { useEffect, useRef, useState } from 'react';
import SingleField from './SingleField';
import { ISingleEntitie } from '../types/interfaces';
import { FormikProps } from 'formik';
import ApplicationCommentsBtn from './ApplicationCommentsBtn';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '../../../config/axiosConfig';
import ApplicationCommentsSidebar from './ApplicationCommentsSidebar';
import gsap from 'gsap';
import { useParams } from 'react-router-dom';

interface IProps {
  data: ISingleEntitie;
  formik: FormikProps<any>;
  index: number;
  isCurrentStep: boolean;
  canEdit: boolean;
  setUnauthorized: React.Dispatch<React.SetStateAction<boolean>>;
}

const SingleEntity = ({ data, formik, index, isCurrentStep, canEdit, setUnauthorized }: IProps) => {
  const { i18n } = useTranslation();
  const [expanded, setExpanded] = useState(0);
  const [currOpenComments, setCurrOpenComments] = useState<string>('');
  const { id } = useParams();

  const animationRef = useRef<gsap.core.Timeline>();

  useEffect(() => {
    formik.setFieldValue(`entity.${index}.entity_id`, data?.id);
  }, [data, index]);

  const entityCommentQuery = useQuery({
    queryKey: ['comments', data?.id],
    queryFn: async () => await axiosInstance(`entity/${data.id}/comments/${id}`, {})
  });

  const sidebarRef = useRef<HTMLDivElement>(null);

  function closeCommentsSide() {
    if (animationRef?.current) animationRef?.current?.kill();
    const tl: gsap.core.Timeline = gsap.timeline();
    animationRef.current = tl;
    const sidebarOverlay: HTMLDivElement = sidebarRef?.current?.closest('.comments-overlay') as HTMLDivElement;
    tl.to(sidebarRef?.current, {
      x: 1000,
      duration: 0.8
    })
      .to(
        sidebarOverlay,
        {
          autoAlpha: 0,
          duration: 0.3
        },
        '<'
      )
      .call(() => {
        setCurrOpenComments('');
      });
  }

  return (
    <>
      <ApplicationCommentsSidebar
        sideRef={sidebarRef}
        comments={entityCommentQuery?.data?.data?.data}
        closeCommentsSide={closeCommentsSide}
        entity_name={data.name[i18n.language as keyof typeof data.name]}
        skeleton_loader={entityCommentQuery.isFetching}
        commentEntityId={Number(data?.id)}
      />
      <div className="field-array-container">
        <Accordion
          expanded={expanded === Number(data?.id)}
          onChange={(e) => {
            e.stopPropagation();
            setExpanded(expanded === Number(data?.id) ? -1 : Number(data?.id));
          }}
        >
          <AccordionSummary
            sx={{
              '&.Mui-focusVisible': {
                backgroundColor: '#000'
              }
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={`header-container ${expanded !== Number(data?.id) && 'expand'}`}
          >
            <p>
              <ExpandAccordion expand={expanded === Number(data?.id)} /> {data?.name[i18n.language as keyof typeof data.name]}
            </p>
            <ApplicationCommentsBtn
              currOpenComments={currOpenComments}
              setCurrOpenComments={setCurrOpenComments}
              comments={entityCommentQuery.data?.data?.data}
              id={data?.id}
              closeCommentsSide={closeCommentsSide}
              sidebarRef={sidebarRef}
              animationRef={animationRef}
            />
          </AccordionSummary>
          <AccordionDetails>
            <div className="fields_container">
              {data?.fields?.map((item: any, fieldIndex: number) => (
                <SingleField
                  data={item}
                  key={fieldIndex}
                  entityIndex={index}
                  formik={formik}
                  fieldIndex={fieldIndex}
                  isCurrentStep={isCurrentStep}
                  canEdit={canEdit}
                  setUnauthorized={setUnauthorized}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default SingleEntity;
