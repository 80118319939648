import { useEffect, useRef, useState } from 'react';
import Button from '../../../components/buttons/Button';
import { commentIcon } from '../../../config/variables';
import gsap from 'gsap';
import { ICommentsCategory } from '../types/interfaces';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

export default function ApplicationCommentsBtn({
  currOpenComments,
  setCurrOpenComments,
  comments,
  id,
  closeCommentsSide,
  sidebarRef,
  animationRef
}: {
  currOpenComments: string;
  setCurrOpenComments: React.Dispatch<React.SetStateAction<string>>;
  id: string;
  comments: ICommentsCategory[];
  closeCommentsSide: () => void;
  sidebarRef: React.MutableRefObject<HTMLDivElement | null>;
  animationRef: React.MutableRefObject<gsap.core.Timeline | undefined>;
}) {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  function openCommentsSide() {
    setCurrOpenComments(id);
    setActive(true);
    if(animationRef?.current) animationRef?.current?.kill()
    const tl: gsap.core.Timeline = gsap.timeline();
    animationRef.current = tl
    const sidebarOverlay: HTMLDivElement = sidebarRef?.current?.closest('.comments-overlay') as HTMLDivElement;
    tl.to(sidebarRef?.current, { x: 0, duration: 0.3 }).to(sidebarOverlay, { autoAlpha: 1, duration: 0.3 }, 0);
  }

  useEffect(() => {
    if (!currOpenComments && active) {
      closeCommentsSide();
      setActive(false);
    }
  }, [closeCommentsSide, currOpenComments]);

  useEffect(() => {
    function handleClickOutside(e: any) {
      if (sidebarRef.current && active && !sidebarRef.current.contains(e.target) && !e.target.closest('.load-more_btn')) closeCommentsSide();
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [active, sidebarRef?.current]);

  return (
    <div className="comments comment-steps-btn" ref={containerRef}>
      <Button
        type="button"
        text={
          <div className="comments-btn">
            {commentIcon} {!isMobile && t('comments')}
            <span>( {comments?.length} )</span>
          </div>
        }
        commentBtn
        onClick={() => {
          openCommentsSide();
        }}
      />
    </div>
  );
}
