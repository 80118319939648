import { useState } from 'react';
import ModalContainer from '../../../components/ModalContainer';
import DeleteModal from '../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { deleteIcon, editIcon } from '../../../config/variables';
import { hasPermission } from '../../../utils/HelperFunctions';

const UsersTableContainer = ({ tableHeaders, data, noDataMessage, setRefetchData, border, setOpenModel, setUserId }: any) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roles } = useSelector((store: any) => store.rolesData);
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

  return (
    <div className={`table_container ${border && 'border'}  ${isDesktop && 'user_table'} hasPagination `}>
      <div className="table_header">
        {tableHeaders?.map((header: any, index: number) => (
          <span className={`head ${header.customClass}`} key={index}>
            {header.label}
          </span>
        ))}
      </div>
      <div className={`table_data ${(!data || data?.length == 0) && 'no_data'}`}>
        {data?.length > 0 ? (
          <>
            {data?.map((item: any, index: number) => (
              <div
                className="item  has_logo pointer"
                key={index}
                onClick={() => {
                  const contentContainer = document.querySelector('.layout_content');
                  contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                  navigate(`/sales/${item?.id}`);
                }}
              >
                <div className="column ">{item?.first_name || '-'}</div>
                <div className="column ">{item?.last_name || '-'}</div>
                <div className="column ">{item?.email || '-'}</div>
                <div className="column ">{item?.mobile || '-'}</div>
                {/* <div className="column ">{roles?.find((role: any) => role.value == item?.role_id)?.label || '-'}</div> */}
                {hasPermission(['users.edit']) && (
                  <div className="column actions actions_col">
                    <div
                      className="action_btn edit"
                      onClick={(e) => {
                        const contentContainer = document.querySelector('.layout_content');
                        contentContainer?.scrollTo({ top: 0, behavior: 'smooth' });
                        e.stopPropagation();
                        navigate(`/sales/create-sales-executive/${item.id}`);
                      }}
                    >
                      {editIcon}{' '}
                    </div>
                    <div
                      className="action_btn delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteModalOpen(item.id);
                      }}
                    >
                      {deleteIcon}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <h6>{noDataMessage}</h6>
        )}
      </div>
      {deleteModalOpen && (
        <ModalContainer small>
          <DeleteModal
            id={deleteModalOpen}
            setModal={setDeleteModalOpen}
            setRefetchData={setRefetchData}
            route={`/admin/users/${deleteModalOpen}/delete`}
            successMsg={t('user_deleted')}
            warningMsg={t('user_status_update')}
            user
          />
        </ModalContainer>
      )}
    </div>
  );
};

export default UsersTableContainer;
