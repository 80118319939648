import Cookies from 'js-cookie';
import FieldWrapper from '../../../components/formInputs/FieldWrapper';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import TextContainer from './TextContainer';
import { IUserData } from '../types/Interfaces';
import { loginPostRequest } from '../API/api';
import { useDispatch } from 'react-redux';
import { setUserData, setUserPermissions, setUserRoles, setUserToken } from '../store/redux/authData';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const updateToken = (token: string) => {
    dispatch(setUserToken(token));
    Cookies.set('token', token);
  };

  const updateUserData = (data: IUserData) => {
    dispatch(setUserData(data));
    localStorage.setItem('user_data', JSON.stringify(data));
  };

  const updateUserPermissions = (data: string[]) => {
    dispatch(setUserPermissions(data));
    localStorage.setItem('user_permissions', JSON.stringify(data));
  };
  const updateUserRoles = (data: string[]) => {
    dispatch(setUserRoles(data));
    localStorage.setItem('user_roles', JSON.stringify(data));
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Enter valid email format')
      .required('required')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Please remove spaces'),
    password: Yup.string()
      .required('required')
      .matches(/^[^ ]\S*/, 'Please remove spaces')
      .min(8, 'The password must be at least 8 characters.')
  });

  return (
    <div className="login_form">
      <TextContainer title={t('login')} desc={t('welcome')} />
      <Formik
        validateOnMount
        validationSchema={validationSchema}
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={(values) => {
          setLoading(true);
          loginPostRequest({ route: '/admin/auth/login', values: values }).then((res) => {
            setLoading(false);
            if (res?.code === 200 && res?.data?.token) {
              updateToken(res?.data?.token?.plainTextToken);
              updateUserData(res?.data?.user);
              updateUserPermissions(res?.data?.user.roles[0].permissions.map((item: { name: string }) => item.name));
              updateUserRoles(res?.data?.user.roles.map((item: { id: string }) => item.id));
              res?.data?.user && navigate('/');
            } else if (res?.errors?.credentials) {
              toast.error(t('invalidCredentials'));
            } else if (res?.message) {
              toast.error(res?.message);
            } else {
              toast.error(t('somethingWrong'));
            }
          });
        }}
      >
        {(formik) => (
          <>
            <Form>
              <FieldWrapper inputName={'email'} inputPlaceholder={t('Email')} inputError={formik.errors.email} inputTouched={formik.touched.email} input tick />
              <FieldWrapper
                inputName={'password'}
                inputPlaceholder={t('Password')}
                inputError={formik.errors.password}
                inputTouched={formik.touched.password}
                input
                type={'password'}
                tick
                customClass={'password-input'}
              />
              <div className="form_button double">
                <Button loading={loading}>
                  <span className="bold">{t('login')}</span>
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
